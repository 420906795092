import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdditionalInformationComponent } from '../../../components/as-candidate/create-profile/additional-information/additional-information.component';
import { CertificatesComponent } from '../../../components/as-candidate/create-profile/certificates/certificates.component';
import { DocumentUploadComponent } from '../../../components/as-candidate/create-profile/document-upload/document-upload.component';
import { DocumentValidationComponent } from '../../../components/as-candidate/create-profile/document-validation/document-validation.component';
import { PersonalInfoComponent } from '../../../components/as-candidate/create-profile/personal-info/personal-info.component';
import { ProfileImageDescriptionComponent } from '../../../components/as-candidate/create-profile/profile-image-description/profile-image-description.component';
import { SkillExpertiseComponent } from '../../../components/as-candidate/create-profile/skill-expertise/skill-expertise.component';
import { WorkExperienceComponent } from '../../../components/as-candidate/create-profile/work-experience/work-experience.component';
import { candidateProfileData } from '../../../shared/enums/candidateProfileData.enum';
import { ICandidate } from '../../../shared/interfaces/candidate.interface';
import { CandidateModel } from '../../../shared/models/candidate.model';
import { MetaDataNameModel } from '../../../shared/models/metaDataName.model';
import { SkillExpertiseByCategoriesModel } from '../../../shared/models/skillExpertiseByCategories.model';
import { TechfaceProvider } from '../../../shared/providers/techface.provider';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { IUpdateCandidate } from '../../../shared/interfaces/updateCandidate.interface';

export class StepModel {
  label: string;
  isActive: boolean;
}
@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss'],
})
export class CreateProfileComponent implements OnInit, OnDestroy {
  stepperData: StepModel[];
  currentStep: StepModel;
  @ViewChild('personalInfo', { static: false })
  personalInfo: PersonalInfoComponent;

  @ViewChild('profileImageDescription', { static: false })
  profileImageDescription: ProfileImageDescriptionComponent;

  @ViewChild('documentUpload', { static: false })
  documentUpload: DocumentUploadComponent;
  private subscriptions: any = [];

  @ViewChild('documentValidation', { static: false })
  documentValidation: DocumentValidationComponent;

  @ViewChild('workExperience', { static: false })
  workExperience: WorkExperienceComponent;

  @ViewChild('certificates', { static: false })
  certificates: CertificatesComponent;

  @ViewChild('additionalInformation', { static: false })
  additionalInformation: AdditionalInformationComponent;

  @ViewChild('skillExpertise', { static: false })
  skillExpertise: SkillExpertiseComponent;

  candidatePersonalInfoData: ICandidate;
  candidateProfileImageDescriptionData: ICandidate;
  documentUploadData: CandidateModel;
  documentValidationData: CandidateModel;
  candidateSkillExpertise: CandidateModel;
  candidateWorkExperience: CandidateModel;
  candidateCertificates: CandidateModel;
  candidateAdditionalInformation: CandidateModel;

  allSkillsExpertiseByCategories: SkillExpertiseByCategoriesModel;
  allLanguages: MetaDataNameModel[] = [];
  allIndustries: MetaDataNameModel[] = [];
  dontShowAgain: boolean;
  constructor(
    private techfaceProvider: TechfaceProvider,
    public navigationService: NavigationService,
    private route: ActivatedRoute
  ) {
    this.setStepperData();
  }

  @HostBinding('unload')
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      let step = parseInt(params.step);
      if (
        Number.isInteger(step) &&
        step >= 0 &&
        step < this.stepperData.length
      ) {
        this.continueToNextStep(step);
        this.setCurrentStep();
        this.getCandidateById();
        this.getAllMetaData();
      }
    });
  }

  setStepperData(): void {
    this.stepperData = [
      { label: candidateProfileData.PERSONAL_INFO, isActive: true },
      { label: candidateProfileData.PROFILE_IMG_DESC, isActive: false },
      { label: candidateProfileData.DOCUMENT_UPLOAD, isActive: false },
      { label: candidateProfileData.DOCUMENT_VALIDATION, isActive: false },
      { label: candidateProfileData.SKILL_EXPERTISE, isActive: false },
      { label: candidateProfileData.WORK_EXPERIENCE, isActive: false },
      { label: candidateProfileData.CERTIFICATION, isActive: false },
      { label: candidateProfileData.ADDITIONAL_INFO, isActive: false },
    ];
  }

  getCandidateById(): void {
    this.subscriptions.push(
      this.techfaceProvider
        .getCandidateById(localStorage.getItem('candidateId'))
        .subscribe((response) => {
          if (response) {
            this.bindCandidateData(response);
          }
        })
    );
  }

  onClickContinue(): void {
    this.onClickSave()
    const stepIndex = this.stepperData.findIndex(step => step.label === this.currentStep.label);
    if (stepIndex === this.stepperData.length - 1)
      this.navigationService.navigateByUrl('me');
    else
      this.navigationService.navigateByUrl(`me/edit/${stepIndex + 1}`);


  }

  onClickStepper(step: number) {
    this.onClickSave();
    this.navigationService.navigateByUrl('me/edit/' + step)
  }

  onClickSave() {
    var candidateObj: IUpdateCandidate | undefined
    switch (this.currentStep.label) {
      case candidateProfileData.PERSONAL_INFO: 
        candidateObj = this.personalInfo.getCandidateObject();
        break;
      case candidateProfileData.PROFILE_IMG_DESC:
        candidateObj = this.profileImageDescription.getCandidateObject();
        break;
      case candidateProfileData.DOCUMENT_UPLOAD:
        candidateObj = this.documentUpload.getCandidateObject();
        break;
      case candidateProfileData.DOCUMENT_VALIDATION:
      case candidateProfileData.SKILL_EXPERTISE:
        break;
      case candidateProfileData.WORK_EXPERIENCE:
        candidateObj = this.workExperience.getCandidateObject();
        break;
      case candidateProfileData.CERTIFICATION:
        candidateObj = this.certificates.getCandidateObject();
        break;
      case candidateProfileData.ADDITIONAL_INFO:
        candidateObj = this.additionalInformation.getCandidateObject();
        break;
    }
    if (candidateObj) {
      this.subscriptions.push(this.techfaceProvider.updateCandidate(candidateObj)
        .subscribe())
    }
  }

  setCurrentStep(): void {
    this.currentStep = this.stepperData.filter(
      (step) => step.isActive === true
    )[0];
  }

  onClickLater(): void {

    this.onClickSave();
    setTimeout(() => this.navigationService.navigateByUrl('me'), 700);
  }

  getAllMetaData(): void {
    this.subscriptions.push(
      this.techfaceProvider.getAllMetaData().subscribe((metadata) => {
        if (metadata) {
          this.allLanguages = metadata.languages.map((languages) => {
            return {
              id: languages._id,
              languageId: languages._id,
              name: languages.name,
            };
          });
        }
      })
    );
    this.subscriptions.push(
      this.techfaceProvider
        .getAllSkillExprtiseByCategory()
        .subscribe((skillExpertise) => {
          if (skillExpertise) {
            this.allSkillsExpertiseByCategories = skillExpertise;
          }
        })
    );
  }

  bindCandidateData(candidate: CandidateModel): void {
    this.candidatePersonalInfoData = {
      firstName: candidate.firstName,
      lastName: candidate.lastName,
      phone: candidate.phone,
      mobile: candidate.mobile,
      streetHouseNumber: candidate.streetHouseNumber,
      city: candidate.city,
      country: candidate.country,
      zipCode: candidate.zipCode,
      languages: candidate.languages,
      validating: candidate.validating,
    };
    this.candidateProfileImageDescriptionData = {
      imageDescription: candidate.imageDescription,
      image: candidate.image,
      title: candidate.title,
    };
    this.documentUploadData = {
      cvDocument: candidate.cvDocument,
      documents: candidate.documents,
    };
    this.documentValidationData = {
      firstName: candidate.firstName,
      validating: candidate.validating,
      cvDocument: candidate.cvDocument,
    };
    this.candidateSkillExpertise = {
      skills: candidate.skills,
      expertises: candidate.expertises,
      displayIndicators: candidate.displayIndicators,
      validating: candidate.validating,
    };
    this.candidateWorkExperience = {
      firstName: candidate.firstName,
      cv: candidate.cv,
    };
    this.candidateCertificates = {
      firstName: candidate.firstName,
      certificates: candidate.certificates,
    };
    this.candidateAdditionalInformation = {
      availableFrom: candidate.availableFrom,
      preferredLocation: candidate.preferredLocation,
      availabilty: candidate.availabilty,
      workPermit: candidate.workPermit,
    };
    this.skillExpertise?.spinner.hide();
  }

  continueToNextStep(stepNumber: number): void {
    if (stepNumber < 0 || stepNumber >= this.stepperData.length) stepNumber = 0;

    this.stepperData.forEach((item, i) => (item.isActive = stepNumber === i));
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }
}
