import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IUpdateCandidate } from '../../../../shared/interfaces/updateCandidate.interface';
import { CandidateModel } from '../../../../shared/models/candidate.model';
import { TechfaceProvider } from '../../../../shared/providers/techface.provider';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss'],
})
export class CertificatesComponent implements OnInit {
  certificates: FormGroup;
  @Input() candidateCertificates: CandidateModel;
  control: any;
  maxDate = new Date();
  constructor(
    private fb: FormBuilder,
    private techfaceProvider: TechfaceProvider
  ) {}
  ngOnChanges(): void {
    this.certificates = this.fb.group({
      certificates: this.fb.array([]),
    });
    this.control = <FormArray>this.certificates.get('certificates');
    this.patch();
  }

  ngOnInit(): void {}
  onClickCertificate(): void {
    this.control.push(this.patchValues('', undefined, ''));
  }
  onclickRemove(index): void {
    this.control.removeAt(index, 1);
  }

  patch(): void {
    this.candidateCertificates?.certificates.forEach((certificate) => {
      this.control.push(
        this.patchValues(
          certificate.certificateName,
          certificate.from,
          certificate.customInformation
        )
      );
    });
  }

  patchValues(
    certificateName: string,
    from: Date,
    customInformation: string
  ): any {
    return this.fb.group({
      certificateName: [certificateName],
      from: [from],
      customInformation: [customInformation],
    });
  }
  getCandidateObject() {
    return {
      id: localStorage.getItem('candidateId'),
      candidateObj: {
        certificates: this.certificates.value.certificates.filter(cert => !!cert.certificateName),
      },
    } as IUpdateCandidate;
  }

}
