<div class="personal-info-container">
  <form
    [formGroup]="personalInfo"
    #myForm="ngForm"
    class="personal-info-wrapper"
  >
    <div class="line">
      <div class="line-title">CONTACT INFO</div>
      <div>
        <label>Name</label>
        <input
          formControlName="name"
          name="name"
          id="name"
          [attr.disabled]="true"
        />
      </div>
      <div>
        <label>Last Name</label>
        <input
          formControlName="lastName"
          name="lastName"
          id="lastName"
          [attr.disabled]="true"
        />
      </div>
      <div>
        <label>E-mail</label>
        <input
          formControlName="email"
          name="email"
          id="email"
          [attr.disabled]="true"
        />
      </div>
      <div>
        <label>Phone</label>
        <input
          matInput
          formControlName="phone"
          name="phone"
          id="phone"
          placeholder="Please enter phone"
        />
      </div>
      <div>
        <label>Mobile</label>
        <input
          matInput
          formControlName="mobile"
          name="mobile"
          id="mobile"
          placeholder="Please enter mobile"
        />
      </div>
    </div>
    <div class="line">
      <div class="line-title">LOCATION</div>
      <div>
        <label>Street & House Number</label>
        <input
          matInput
          formControlName="streetHouseNumber"
          name="streetHouseNumber"
          id="streetHouseNumber"
          placeholder="Please enter street & house number"
        />
      </div>
      <div>
        <label>City</label>
        <input
          matInput
          formControlName="city"
          name="city"
          id="city"
          placeholder="Please enter city"
        />
      </div>
      <div>
        <label>Zip Code</label>
        <input
          type="number"
          matInput
          formControlName="zipCode"
          name="zipCode"
          id="zipCode"
          placeholder="Please enter Zip Code"
        />
      </div>
      <div>
        <label>Country</label>
        <input
          matInput
          formControlName="country"
          name="country"
          id="country"
          placeholder="Please enter country"
        />
      </div>
    </div>
    <div class="line">
      <div class="line-title">LANGUAGES</div>
      <div *ngFor="let count of languagesCount; let i = index">
        <div class="language">
          <div class="label">
            <label>Language</label>
            <div class="remove-language" (click)="languagesCount.pop()">x</div>
          </div>
          <mat-select
            id="language{{ i }}"
            formControlName="language{{ i }}"
            name="language"
            bindLabel="language"
            placeholder="Please Select"
          >
            <mat-option
              *ngFor="let language of languagesData; let i = index"
              [value]="language"
            >
              {{ language.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="btn btn-secondary" (click)="addLanguage()">
        + <span>Add Language</span>
      </div>
    </div>
  </form>
</div>
