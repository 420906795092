import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ICandidate } from '../../../../shared/interfaces/candidate.interface';
import { IUpdateCandidate } from '../../../../shared/interfaces/updateCandidate.interface';
import { TechfaceProvider } from '../../../../shared/providers/techface.provider';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { UploadService } from '../../../../shared/services/services/upload.service';
import { IUploadURL } from '../../../../shared/models/uploadUrl.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-profile-image-description',
  templateUrl: './profile-image-description.component.html',
  styleUrls: ['./profile-image-description.component.scss'],
})
export class ProfileImageDescriptionComponent implements OnChanges, OnDestroy {
  url: any;
  msg = '';
  html = '';
  profileImageDescription: FormGroup;
  @Input() candidateData: ICandidate;
  private subscriptions = [];
  constructor(
    private fb: FormBuilder,
    private techfaceProvider: TechfaceProvider,
    private uploadService: UploadService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnChanges(): void {
    this.url = this.candidateData?.image;
    this.profileImageDescription = this.fb.group({
      imageDescription: [this.candidateData?.imageDescription],
      image: [this.candidateData?.image],
      title: [this.candidateData?.title],
    });
  }

  selectFile(event: any): void {
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.msg = 'Only images are supported';
      return;
    }
    this.uploadFile(event.target.files[0]);
  }

  getCandidateObject() {
    return {
      id: localStorage.getItem('candidateId'),
      candidateObj: this.profileImageDescription.value,
    } as IUpdateCandidate;
  }

  public dropped(files: NgxFileDropEntry[]): void {
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.uploadFile(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }
  private uploadFile(file: any): void {
    this.spinner.show();
    let url: IUploadURL;

    this.subscriptions.push(
      this.uploadService
        .getUploadUrl(file.name)
        .pipe(
          map((urlObj: IUploadURL) => {
            if (urlObj) {
              url = urlObj;
              return urlObj;
            }
          }),
          mergeMap(() =>
            this.uploadService.uploadFile(
              url.uploadUrl,
              this.renameFile(file, file.name),
              url.contentType
            )
          )
        )
        .subscribe((uploadedFile) => {
          if (uploadedFile.url) {
            this.url = url.fetchUrl;
            this.profileImageDescription.controls['image'].setValue(this.url);
          }
        })
    );
  }

  private renameFile(originalFile: File, newName: string): File {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  public fileOver(event): void {}

  public fileLeave(event): void {}
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }
}
