import {
  Component,
  Input,
  OnChanges,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CandidateModel } from '../../../../shared/models/candidate.model';
import { Options } from 'ng5-slider';
import { IUpdateCandidate } from '../../../../shared/interfaces/updateCandidate.interface';
import { TechfaceProvider } from '../../../../shared/providers/techface.provider';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
})
export class AdditionalInformationComponent implements OnInit, OnChanges {
  @Input() candidateAdditionalInformation: CandidateModel;
  additionalInformation: FormGroup;
  minDate = new Date();
  errorMsg = '';
  options: Options = {
    floor: 0,
    ceil: 100,
    step: 10,
    translate: (value: number): string => {
      this.errorMsg = value === 0 ? 'Work Pensum must be bigger than 0%' : '';
      return value + '%';
    },
  };

  availabilty: FormControl;
  workPermitData = ['Swiss citizen', 'EU/EFTA National', 'Non EU/EFTA National', 'L', 'B', 'C', 'G', 'Protection status S' ];
  constructor(
    private fb: FormBuilder,
    private techfaceProvider: TechfaceProvider
  ) {}
  ngOnInit(): void {}

  ngOnChanges(): void {
    this.setAdditionalInformation();
  }
  /**
   * create personal info form
   */
  setAdditionalInformation(): void {
    this.additionalInformation = this.fb.group({
      availableFrom: [this.candidateAdditionalInformation?.availableFrom],
      preferredLocation: [
        this.candidateAdditionalInformation?.preferredLocation,
      ],
      workPermit: [this.candidateAdditionalInformation?.workPermit],
    });
    if (this.candidateAdditionalInformation.availabilty) {
      this.availabilty = new FormControl([
        this.candidateAdditionalInformation?.availabilty[0],
        this.candidateAdditionalInformation?.availabilty[1],
      ]);
    }
  }

  getCandidateObject() {
    return {
      id: localStorage.getItem('candidateId'),
      candidateObj: {
        availableFrom: this.additionalInformation.value.availableFrom,
        preferredLocation: this.additionalInformation.value.preferredLocation,
        availabilty: this.availabilty.value,
        workPermit: this.additionalInformation.value.workPermit,
      },
    } as IUpdateCandidate;
  }
  
}
