import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ICandidate } from '../../../../shared/interfaces/candidate.interface';
import { IUpdateCandidate } from '../../../../shared/interfaces/updateCandidate.interface';
import { MetaDataNameModel } from '../../../../shared/models/metaDataName.model';
import { TechfaceProvider } from '../../../../shared/providers/techface.provider';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent implements OnInit, OnChanges {
  @Input() languagesData: MetaDataNameModel[];
  @Input() candidateData: ICandidate;

  personalInfo: FormGroup;
  languagesCount: any = [];

  constructor(
    private fb: FormBuilder,
    private techfaceProvider: TechfaceProvider
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.setPersonalInfo();
    this.getLanguages();
  }

  /**
   * create personal info form
   */
  setPersonalInfo(): void {
    this.personalInfo = this.fb.group({
      name: [this.candidateData?.firstName],
      lastName: [this.candidateData?.lastName],
      email: [localStorage.getItem('username')],
      phone: [this.candidateData?.phone],
      mobile: [this.candidateData?.mobile],
      streetHouseNumber: [this.candidateData?.streetHouseNumber],
      city: [this.candidateData?.city],
      zipCode: [this.candidateData?.zipCode],
      country: [this.candidateData?.country],
      languages: this.fb.array([]),
    });
  }

  getCandidateObject() {
    this.setLanguages();
    return {
      id: localStorage.getItem('candidateId'),
      candidateObj: this.personalInfo.value,
    } as IUpdateCandidate;
  }

  /**
   * add language with add personal info control with language id
   */
  addLanguage(): void {
    this.languagesCount.push('');
    for (let i = 0; i < this.languagesCount.length; i++) {
      this.personalInfo.addControl(`language${i}`, new FormControl(''));
    }
  }

  /**
   * get language return with candidate id
   */
  getLanguages(): void {
    this.languagesCount = this.languagesData.filter(function (el) {
      return this.has(el.languageId);
    }, new Set(this.candidateData?.languages.map((el) => el.languageId)));
    for (let i = 0; i < this.languagesCount.length; i++) {
      this.personalInfo.addControl(
        `language${i}`,
        new FormControl(this.languagesCount[i])
      );
    }
  }

  /**
   * set languages into personal info form
   */
  setLanguages(): void {
    const languages = [];
    for (let i = 0; i < this.languagesCount.length; i++) {
      languages.push(this.personalInfo.get(`language${i}`));
      this.personalInfo.setControl('languages', this.fb.array(languages || []));
    }
  }
}
