<div class="create-profile-container">
    <div class="title">
      <h1>{{ currentStep.label }}</h1>
    </div>
    <div class="content">
      <div class="navigation">
        <div
          class="step"
          *ngFor="let step of stepperData; let i = index"
          [ngClass]="{ active: step.isActive }"
        >
          <div class="stepper-label">
            <a (click)="onClickStepper(i);">{{
              step.label
            }}</a>
          </div>
        </div>
      </div>
  
      <div class="details card">
        <div class="card-title">{{ currentStep.label }}</div>
        <div class="card-body">
          <app-personal-info
            *ngIf="currentStep.label === stepperData[0].label"
            #personalInfo
            [languagesData]="allLanguages"
            [candidateData]="candidatePersonalInfoData"
          ></app-personal-info>
          <app-profile-image-description
            #profileImageDescription
            *ngIf="currentStep.label === stepperData[1].label"
            [candidateData]="candidateProfileImageDescriptionData"
          ></app-profile-image-description>
          <app-document-upload
            #documentUpload
            [candidateData]="documentUploadData"
            *ngIf="currentStep.label === stepperData[2].label"
          ></app-document-upload>
          <app-documten-validation
            #documentValidation
            [candidateData]="documentValidationData"
            *ngIf="currentStep.label === stepperData[3].label"
          ></app-documten-validation>
          <app-skill-expertise
          #skillExpertise
            *ngIf="currentStep.label === stepperData[4].label"
            [allSkillsExpertiseByCategories]="allSkillsExpertiseByCategories"
            [candidateSkillExpertise]="candidateSkillExpertise"
            (getDataEvent)="getCandidateById()"
          ></app-skill-expertise>
          <app-work-experience
            *ngIf="currentStep.label === stepperData[5].label"
            #workExperience
            [candidateWorkExperience]="candidateWorkExperience"
          ></app-work-experience>
          <app-certificates
            *ngIf="currentStep.label === stepperData[6].label"
            #certificates
            [candidateCertificates]="candidateCertificates"
          ></app-certificates>
          <app-additional-information
            #additionalInformation
            *ngIf="currentStep.label === stepperData[7].label"
            [candidateAdditionalInformation]="candidateAdditionalInformation"
          ></app-additional-information>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <span class="later-btn btn btn-link" (click)="onClickLater()"
        >Jump to my profile</span
      >
      <button class="continue-btn btn btn-primary" (click)="onClickContinue()">
        Continue
      </button>
    </div>
</div>
